@import "helpers/helpers";

@font-face {
  font-family: 'Calibre';
  src: url('assets/fonts/Calibre-Light.eot');
  src: url('assets/fonts/Calibre-Light.eot?#iefix') format('embedded-opentype'),
      url('assets/fonts/Calibre-Light.woff2') format('woff2'),
      url('assets/fonts/Calibre-Light.woff') format('woff'),
      url('assets/fonts/Calibre-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre';
  src: url('assets/fonts/Calibre-Medium.eot');
  src: url('assets/fonts/Calibre-Medium.eot?#iefix') format('embedded-opentype'),
      url('assets/fonts/Calibre-Medium.woff2') format('woff2'),
      url('assets/fonts/Calibre-Medium.woff') format('woff'),
      url('assets/fonts/Calibre-Medium.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Sectra';
  src: url('assets/fonts/GTSectra-Book.eot');
  src: url('assets/fonts/GTSectra-Book.eot?#iefix') format('embedded-opentype'),
      url('assets/fonts/GTSectra-Book.woff2') format('woff2'),
      url('assets/fonts/GTSectra-Book.woff') format('woff'),
      url('assets/fonts/GTSectra-Book.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Sectra';
  src: url('assets/fonts/GTSectra-Book.eot');
  src: url('assets/fonts/GTSectra-Book.eot?#iefix') format('embedded-opentype'),
      url('assets/fonts/GTSectra-Book.woff2') format('woff2'),
      url('assets/fonts/GTSectra-Book.woff') format('woff'),
      url('assets/fonts/GTSectra-Book.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Sectra';
  src: url('assets/fonts/GTSectra-Book-Italic.eot');
  src: url('assets/fonts/GTSectra-Book-Italic.eot?#iefix') format('embedded-opentype'),
      url('assets/fonts/GTSectra-Book-Italic.woff2') format('woff2'),
      url('assets/fonts/GTSectra-Book-Italic.woff') format('woff'),
      url('assets/fonts/GTSectra-Book-Italic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Sectra';
  src: url('assets/fonts/subset-GTSectraDisplay-Light.eot');
  src: url('assets/fonts/subset-GTSectraDisplay-Light.eot?#iefix') format('embedded-opentype'),
      url('assets/fonts/subset-GTSectraDisplay-Light.woff2') format('woff2'),
      url('assets/fonts/subset-GTSectraDisplay-Light.woff') format('woff'),
      url('assets/fonts/subset-GTSectraDisplay-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}



@font-face {
  font-family: 'ITC';
  src: url('assets/fonts/ITCAvantGardeStd-XLt.eot');
  src: url('assets/fonts/ITCAvantGardeStd-XLt.eot?#iefix') format('embedded-opentype'),
      url('assets/fonts/ITCAvantGardeStd-XLt.woff2') format('woff2'),
      url('assets/fonts/ITCAvantGardeStd-XLt.woff') format('woff'),
      url('assets/fonts/ITCAvantGardeStd-XLt.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ITC';
  src: url('assets/fonts/ITCAvantGardeStd-Bk.eot');
  src: url('assets/fonts/ITCAvantGardeStd-Bk.eot?#iefix') format('embedded-opentype'),
      url('assets/fonts/ITCAvantGardeStd-Bk.woff2') format('woff2'),
      url('assets/fonts/ITCAvantGardeStd-Bk.woff') format('woff'),
      url('assets/fonts/ITCAvantGardeStd-Bk.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}




/* Define commonly used colors and fonts as variables */
:root {
/* Colors */
--black: #000000;
--white: rgb(255,255,255);
--whiteFaded: rgba(255,255,255, .7);
--goldValue: 149,122,63;
--gold: rgb(var(--goldValue));
--goldFaded: rgba( var(--goldValue), .9 );
--blue: #1f525e;
--brightBlue: #2F5285;
--red: #b42000;
--brown: #1f1915;
--brownValue: 31, 25, 21;
--brownFaded: rgba( var(--brownValue), .9 );
--darkGold: #6d5d36;
--darkGoldValue: 109, 93, 54;
--darkGoldFaded: rgba( var(--darkGoldValue), .8);
/* Fonts */
--f1: normal 1.7rem/1.4 "Calibre", sans-serif;
--f2: normal 1.8rem/1.4 "Sectra", serif;
--f3: normal 2.4rem/1.4 "ITC", sans-serif;
--f4: bold 1.8rem/1.4 "Sectra", serif;
}

html {
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
}

body {
font: var(--f1);
letter-spacing: 2.07px;
color: var(--white);
background: var(--black);
}

a {
color: inherit;
text-decoration: none;
}

sup {
  top: -0.2em;
}

/* Define Commonly Used classes here if any — although majority of css should be in the components */
:global .mxa {
margin-left: auto;
margin-right: auto;
}

/* fullpage.js section */
/* :global .section {
  max-width: 1168px;
  margin: 0 auto;
} */

:global .sectionInner {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 80px;

  @include mq($until: desktop) {
    padding: 0 36px;
  }
}

:global .bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('~/app/assets/images/bg.jpg');
  background-position: 50% 50%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0;
  transition: .5s opacity;
}

:global .bgReady {
  opacity: 1;
}


:global #fp-nav.fp-right {
  opacity: 0;
  // pointer-events: none;
  right: 30px;

  @include mq($until: desktop) {
    right: 0px !important;
    top: 0;
    height: 100%;
    margin: 0 !important;
  }
}
:global #fp-nav.fp-right ul {
  position: absolute;
  bottom: 23px;
  right: 22px;

  @include mq($until: desktop) {

  }
}
:global #fp-nav.fp-right ul li {
  width: 16px;
  height: 15px;
  margin: 11px 7px;
}
:global #fp-nav.fp-right ul li a span {
  width: 5px;
  height: 5px;
  transform: translate(-50%, -50%);
  margin: 0;
  background: white;
  opacity: .3;
  transition: .3s opacity;
}
:global #fp-nav.fp-right ul li a.active span,
:global #fp-nav.fp-right ul li:hover a span,
:global #fp-nav.fp-right ul li:hover a.active span {
  width: 5px;
  height: 5px;
  margin: 0;
  background: white;
  opacity: .75;
}
:global .fp-sr-only {
  display: none!important;
}

:global .grecaptcha-badge {
  visibility: hidden !important;
}

/* :global #fp-nav.descOpen {
  display: none;
} */
