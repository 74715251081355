@import "mq";

$mq-breakpoints: (
    mobile:  320px,
    mobileWide: 374px,
    tablet:  740px,
    desktop: 980px,
    1024: 1024px,
    tuningPageText: 1100px,
    textBoxBreak: 1280px,
    wide:    1300px,
    xwide:   1440px,
    xxwide:  1800px
);

%btn {
  background: none !important;
  border: 2px solid rgb(241, 241, 241) !important;
  border-radius: 2px !important;
  color: var(--white) !important;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 5px;
  line-height: 1;
  outline: currentcolor none medium !important;
  padding: 16px 30px !important;
  text-transform: uppercase;
  text-decoration: none;
  transition: background .3s, border .15s ease-out, opacity .3s, 0.3s color;
  user-select: none;
  vertical-align: top;
  width: auto;
  opacity: 0.7;

  &:hover {
    background: var(--white) !important;
    color: var(--gold) !important;
    opacity: 1;
  }

  @include mq($from: xxwide) {
    font-size: 17px;
  }
}

// maybe implenment a few font styles… but this isn't good for some "copy" areas of the site
%copy {
  font: var(--f1);
  letter-spacing: 2.07px;
  color: rgba(255,255,255,.7);

  @include mq($from: xxwide) {
    font-size: 19px;
  }
}

%header {
  font: var(--f1);
  color: var(--gold);
  letter-spacing: 5px;

  @include mq($from: xxwide) {
    font-size: 19px;
  }
}